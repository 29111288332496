import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook for navigation
import Toolbar from '../Toolbar/Toolbar.js';
import { useAuth } from "../../hooks/useAuth.js";
import './GroupsPage.css'; 

const GroupsPage = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const colors = [
    '#FF6F61', 
    '#6667AB', 
    '#FFC196',
  ];

  const { user } = useAuth();
  const navigate = useNavigate(); // Hook for navigation

  const userData = {
    user_id: user.userId,
  };

  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/get-user-groups`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(userData),
          }); 
        const group_data = await response.json();
        const uniqueGroups = Object.values(
          group_data.reduce((acc, item) => {
            // If the group_id doesn't already exist, add it to the accumulator
            if (!acc[item.group_id]) {
              acc[item.group_id] = { group_id: item.group_id, group_name: item.group_name };
            }
            return acc;
          }, {})
        );
        setGroups(uniqueGroups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  // Handle group click to navigate to the amount owed page
  const handleGroupClick = (groupId) => {
    navigate(`/group/${groupId}/group-dash`); // Navigate to the specific group amount owed page
  };

  if (loading) {
    return <div>Loading groups...</div>;
  }

  return (
    <div>
      <Toolbar />
      <div style={styles.container}>
        <h1 style={styles.title}>Your Groups</h1>
        <div className='groups-container'>
          {groups.map((group, index) => (
            <div 
              key={group.group_name} 
              className="group-tile" 
              onClick={() => handleGroupClick(group.group_id)} // Attach the click handler here
              style={{ backgroundColor: colors[index % colors.length] }}
            >
              {group.group_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#f4f4f4',
      height: '100vh',
    },
    title: {
      marginBottom: '20px',
      color: '#333',
    },
};

export default GroupsPage;
