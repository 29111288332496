import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Toolbar from '../Toolbar/Toolbar.js';
import { useAuth } from "../../hooks/useAuth.js";
import './GroupAmountOwed.css';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import dayjs from 'dayjs';
import ConfirmModal from '../ConfirmSettleModal/ConfirmSettleModal.js';

const OweTracker = () => {
    const [recentData, setRecentData] = useState([]);
    const [categoryBreakdown, setCategoryBreakdown] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryExpensesOverTime, setCategoryExpensesOverTime] = useState([]);
    const [averageMonthlyByCategory, setAverageMonthlyByCategory] = useState([]);
    const [settleUpData, setSettleUpData] = useState([]);

    const { user } = useAuth();
    const { groupId } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const BASE_URL = process.env.REACT_APP_API_URL;

    // Fetch recent expenses
    const fetchRecentData = useCallback(async () => {
        setLoading(true);

        const queryData = {
            user_id: user.userId,
            group_id: groupId,
        };
        try {
            const response = await fetch(`${BASE_URL}/group-recent-expenses`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(queryData),
            });
            const data = await response.json();

            if (Array.isArray(data)) {
                setRecentData(data);

                const categoryData = data.reduce((acc, expense) => {
                    acc[expense.category] = (acc[expense.category] || 0) + expense.amount;
                    return acc;
                }, {});
                setCategoryBreakdown(categoryData);

                if (Object.keys(categoryData).length > 0) {
                    const defaultCategory = Object.keys(categoryData).includes("Groceries")
                        ? "Groceries"
                        : Object.keys(categoryData)[0];
                    setSelectedCategory(defaultCategory);
                }
            } else {
                console.error('Unexpected API response format:', data);
                setRecentData([]);
            }
        } catch (error) {
            console.error('Error fetching recent expenses:', error);
            setRecentData([]);
        } finally {
            setLoading(false);
        }
    }, [groupId, user.userId]);

    // Fetch settle-up data
    const fetchSettleUpData = useCallback(async () => {
        const queryData = { group_id: groupId };
        try {
            const response = await fetch(`${BASE_URL}/group-settleup-data`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(queryData),
            });
            const result = await response.json();
            const simplifiedData = simplifyDebts(result);
            setSettleUpData(simplifiedData);
        } catch (error) {
            console.error('Error fetching settle-up data:', error);
        }
    }, [groupId]);

    // Simplify debts
    const simplifyDebts = (debts) => {
        const balanceSheet = {};

        debts.forEach(({ user_name_responsible, uploader_name, owed_amt }) => {
            balanceSheet[user_name_responsible] = (balanceSheet[user_name_responsible] || 0) - owed_amt;
            balanceSheet[uploader_name] = (balanceSheet[uploader_name] || 0) + owed_amt;
        });

        const positiveBalances = [];
        const negativeBalances = [];

        for (const [user, balance] of Object.entries(balanceSheet)) {
            if (balance > 0) positiveBalances.push({ user, balance });
            if (balance < 0) negativeBalances.push({ user, balance: -balance });
        }

        const simplified = [];
        while (positiveBalances.length && negativeBalances.length) {
            const credit = positiveBalances.pop();
            const debit = negativeBalances.pop();
            const settlementAmount = Math.min(credit.balance, debit.balance);

            simplified.push({ from: debit.user, to: credit.user, amount: settlementAmount });

            if (credit.balance > settlementAmount) {
                positiveBalances.push({ user: credit.user, balance: credit.balance - settlementAmount });
            }
            if (debit.balance > settlementAmount) {
                negativeBalances.push({ user: debit.user, balance: debit.balance - settlementAmount });
            }
        }

        return simplified;
    };

    // Settle expenses and refresh data
    const settleExpenses = async () => {
        const expenseIds = recentData
            .filter((expense) => !expense.settled)
            .map((expense) => expense.expense_id);

        if (expenseIds.length === 0) {
            alert('No unsettled expenses found for this settlement!');
            return;
        }
        try {
            const response = await fetch(`${BASE_URL}/settle-expenses`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ expenseIds }),
            });

            const result = await response.json();

            if (result.success) {
                alert('Expenses settled successfully!');
                await Promise.all([fetchRecentData(), fetchSettleUpData()]);
            } else {
                alert('Error settling expenses: ' + result.message);
            }
        } catch (error) {
            console.error('Error settling expenses:', error);
            alert('An error occurred while settling expenses.');
        }
    };

    // Re-fetch data on mount or when groupId changes
    useEffect(() => {
        fetchRecentData();
        fetchSettleUpData();
    }, [fetchRecentData, fetchSettleUpData]);

    useEffect(() => {
        const categoryDataAvg = recentData.reduce((acc, expense) => {
            const month = dayjs(expense.expense_date).format('YYYY-MM');
            if (!acc[expense.category]) {
                acc[expense.category] = { total: 0, months: new Set() };
            }
            acc[expense.category].total += parseFloat(expense.amount);
            acc[expense.category].months.add(month);
            return acc;
        }, {});

        const averages = Object.entries(categoryDataAvg).map(([category, { total, months }]) => ({
            category,
            average: total / months.size,
        }));

        setAverageMonthlyByCategory(averages);
    }, [recentData]);

    useEffect(() => {
        if (!selectedCategory) return;

        const filteredData = recentData
            .filter((expense) => expense.category === selectedCategory)
            .map((expense) => ({
                month: dayjs(expense.expense_date).format('YYYY-MM'),
                amount: parseFloat(expense.amount),
            }));

        const groupedByMonth = filteredData.reduce((acc, { month, amount }) => {
            acc[month] = (acc[month] || 0) + amount;
            return acc;
        }, {});

        const sortedMonths = Object.keys(groupedByMonth).sort((a, b) => new Date(a) - new Date(b));

        const chartData = sortedMonths.map((month) => ({
            month,
            amount: groupedByMonth[month],
        }));

        setCategoryExpensesOverTime(chartData);
    }, [selectedCategory, recentData]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    const lineChartData = {
        labels: categoryExpensesOverTime.map((entry) => entry.month),
        datasets: [
            {
                label: `Expenses Over Time (${selectedCategory})`,
                data: categoryExpensesOverTime.map((entry) => entry.amount),
                fill: false,
                borderColor: '#36A2EB',
                tension: 0.2,
            },
        ],
    };

    const sortedBarChartData = [...averageMonthlyByCategory].sort((a, b) => b.average - a.average);
    const barChartData = {
        labels: sortedBarChartData.map((item) => item.category),
        datasets: [
            {
                label: 'Avg Monthly Spend',
                data: sortedBarChartData.map((item) => item.average.toFixed(2)),
                backgroundColor: '#4CAF50',
            },
        ],
    };

        // Open the modal and set the selected expense ids
        const handleOpenModal = () => {
            setIsModalOpen(true);
        };
    
        // Close the modal
        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

    return (
        <div>
            <Toolbar />
            <div className="dashboard-container">
                <div className="charts-container">
                    <div className="line-chart-container">
                        <h2>Expenses Over Time</h2>
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                            {Object.keys(categoryBreakdown).map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                        <Line data={lineChartData} height={300} />
                    </div>
                    <div className="bar-chart-container">
                        <h2>Avg Monthly Spend by Category</h2>
                        <Bar
                            data={barChartData}
                            options={{
                                indexAxis: 'y',
                                scales: {
                                    x: {
                                        beginAtZero: true,
                                    },
                                },
                            }}
                            height={300}
                        />
                    </div>
                </div>

                <div className="settle-up-section">
                    <h2>Settle-Up Instructions</h2>
                    {settleUpData.length > 0 ? (
                        settleUpData.map((settlement, index) => (
                            <div key={index} className="settlement-instruction">
                                <p>
                                    {settlement.from} ➔ {settlement.to}: ${settlement.amount.toFixed(2)}
                                </p>
                                <button
                                onClick={() =>
                                        handleOpenModal([settlement.expense_id]) // Pass the expense IDs
                                    }
                                >
                                    Record Payment
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>All debts are settled!</p>
                    )}
                </div>

                <ConfirmModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onConfirm={() => {
                        settleExpenses(); // Call settleExpenses if confirmed
                        handleCloseModal(); // Close modal after confirmation
                    }}
                />

                <div className="recent-expenses">
                    <h2>Most Recent Expenses</h2>
                    <div className="scrollable-table">

                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Uploaded By</th>
                                <th>Your Share</th>
                                <th>Total Amount</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentData.map((expense) => (
                                <tr key={expense.expense_id}
                                    className={expense.settled ? 'settled' : 'unsettled'}
                                    >
                                    <td>{dayjs(expense.expense_date).format('YYYY-MM-DD')}</td>
                                    <td>{expense.payer}</td>
                                    <td>${Number(expense.your_share).toFixed(2)}</td>
                                    <td>${Number(expense.amount).toFixed(2)}</td>
                                    <td>{expense.category}</td>
                                    <td>{expense.description}</td>
                                    <td>{expense.settled ? 'Settled' : 'Unsettled'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OweTracker;
