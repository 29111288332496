import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook for navigation
import Toolbar from '../Toolbar/Toolbar.js';
import './ExpenseUpload.css'; 

const ExpenseUpload = () => {

  const colors = [
    '#0392cf', 
    '#fe8a71', 
    '#FFC196',
  ];

  const navigate = useNavigate(); // Hook for navigation

  const expenseUploadOptions = [
    {
      name: 'Single Upload',
      description: 'Upload a single expense',
      onClick: () => navigate('/single-expense-upload'),
    },
    {
        name: 'Bulk Upload',
        description: 'Upload a CSV file with your expenses',
        onClick: () => navigate('/bulk-expense-upload'),
    },
  ]

  return (
    <div>
      <Toolbar />
      <div style={styles.container}>
        <h1 style={styles.title}>Choose how you want to upload</h1>
        <div className='expense-container'>
          {expenseUploadOptions.map((option, index) => (
            <div 
              key={option.name} 
              className="expense-tile" 
              onClick={option.onClick} // Attach the click handler here
              style={{ backgroundColor: colors[index % colors.length] }}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#f4f4f4',
      height: '100vh',
    },
    title: {
      marginBottom: '20px',
      color: '#333',
    },
};

export default ExpenseUpload;
