import React, { useState } from 'react';
import Papa from 'papaparse';
import { useAuth } from "../../hooks/useAuth.js";
import './NewCsvConfigModal.css'; 

const CsvConfigModal = ({ isOpen, onClose }) => {
  const { user } = useAuth();

  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseDate, setExpenseDate] = useState('');
  const [expenseDescription, setExpenseDescription] = useState('');
  const [configName, setConfigName] = useState('');
  const [isNegative, setIsNegative] = useState(false);

  const generateHeaders = (row) => {
    // Generate generic headers like A, B, C, ...
    return row.map((_, index) => String.fromCharCode(65 + index));
  };
  
  const parseCSV = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: false, // Start by assuming there are no headers
      complete: (results) => {
        const firstRow = results.data[0];
        const headersFromMeta = results.meta.fields;
  
        // Check if the first row has unique keys (indicates headers)
        const isLikelyHeader = firstRow && firstRow.every((value) => typeof value === "string" && value.trim() !== "");
  
        if (isLikelyHeader) {
          // Use the first row as headers
          const dataWithoutFirstRow = results.data.slice(1); // Remove the first row (header)
          setHeaders(firstRow);
          setData(
            dataWithoutFirstRow.map((row) =>
              firstRow.reduce((acc, header, index) => {
                acc[header] = row[index];
                return acc;
              }, {})
            )
          );
        } else if (firstRow) {
          // No headers detected, generate generic ones
          const generatedHeaders = generateHeaders(firstRow);
          setHeaders(generatedHeaders);
          setData(
            results.data.map((row) =>
              row.reduce((acc, value, index) => {
                acc[generatedHeaders[index]] = value;
                return acc;
              }, {})
            )
          );
        } else {
          setError("Unable to parse the CSV file.");
          setData([]);
          setHeaders([]);
        }
      },
      error: (error) => {
        console.error("Error parsing CSV: ", error);
        setError("Failed to parse CSV.");
      },
    });
  };
  

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      parseCSV(uploadedFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const configData = {
      user_id: user.userId, 
      date_column: expenseDate,
      amount_column: expenseAmount,
      desc_column: expenseDescription,
      configuration_name: configName,
      is_negative: isNegative,
    };

    const BASE_URL = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${BASE_URL}/save-configuration`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(configData),
      });

      if (response.ok) {
        console.log('Configuration saved successfully');
        setFile(null);
        setData([]);
        onClose();
      } else {
        console.error('Failed to save configuration');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error saving configuration:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Preview CSV Data</h2>
        {error && <div className="error-message">{error}</div>}
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <br />
        {data.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.slice(0, 3).map((row, index) => ( // Show only the first 3 rows
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={header}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="expense-selector">
              <h3>Transaction Date Column:</h3>
              <select value={expenseDate} onChange={(e) => setExpenseDate(e.target.value)}>
                <option value="">Select a column</option>
                {headers.map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div className="expense-selector">
              <h3>Expense Amount Column:</h3>
              <select value={expenseAmount} onChange={(e) => setExpenseAmount(e.target.value)}>
                <option value="">Select a column</option>
                {headers.map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div className="expense-selector">
              <h3>Expense Description Column:</h3>
              <select value={expenseDescription} onChange={(e) => setExpenseDescription(e.target.value)}>
                <option value="">Select a column</option>
                {headers.map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div className="expense-selector">
              <h3>Expense amounts are shown as negative: </h3>
              <label>
                <input
                  type="checkbox"
                  checked={isNegative}
                  onChange={(e) => setIsNegative(e.target.checked)}
                />
              </label>
            </div>

            <div>
              <h3>Name this configuration</h3>
              <input 
                type="text" 
                value={configName} 
                onChange={(e) => setConfigName(e.target.value)}
                placeholder="example: bank_of_america_checking"
              />
            </div>
          </>
        ) : (
          <p>No data to display.</p>
        )}
        <br />
        {/* <button onClick={handleSubmit} style={{ marginBottom: '10px' }}>Save</button> */}
        <button onClick={handleSubmit} className="save-button">Save</button>
      </div>
    </div>
  );
};

export default CsvConfigModal;
