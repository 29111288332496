// ConfirmModal.js
import React from 'react';
import './ConfirmSettleModal.css'; // You can style it here

const ConfirmModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null; // If modal is closed, render nothing

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h2>Are you sure you want to record this payment?</h2>
                <div className="modal-actions">
                    <button onClick={onConfirm}>Confirm</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
