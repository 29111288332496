import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route  } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import App from './App.js';
// import ChartComponent from './views/MonthlySpendChart.js';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      < App />
    </BrowserRouter>
  </React.StrictMode>
);