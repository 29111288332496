import React, { useState } from 'react';
import { useAuth } from "../../hooks/useAuth.js";
import './CsvCarousel.css'; // Import the CSS file

const ExpenseCarousel = ({ expenses, onUpdate , onDelete, groups, categories}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const { user } = useAuth();
  const currentExpense = expenses[currentIndex];

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % expenses.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + expenses.length) % expenses.length);
  };

  const handleChange = (event) => {
    const updatedExpense = { ...expenses[currentIndex], [event.target.name]: event.target.value };
    onUpdate(currentIndex, updatedExpense);
  };

  const handleGroupChange = (event) => {
    const selectedGroupName = event.target.value;
    const selectedGroup = groups.find(group => group.group_name === selectedGroupName);
    const selectedGroupId = selectedGroup ? selectedGroup.group_id : null;

    // Update the group for the current expense
    const updatedExpense = {
        ...currentExpense,
        group_name: selectedGroupName,
        group_id: selectedGroupId,
        settled: selectedGroupName === "Personal", // Set 'settled' based on the group name
    };

    if (selectedGroupName === "Personal") {
        // For "Personal" group, no user breakdown needed
        updatedExpense.expense_user_breakdown = [{
            user_id: parseInt(user.userId, 10),
            amount: parseFloat(currentExpense.amount || 0),
        }];
    } else {
        // Get the selected group's users
        const selectedUsers = user_options.filter(user => user.group_id === selectedGroupId);

        // Calculate the base split amount
        const totalAmount = parseFloat(currentExpense.amount || 0);
        const userCount = selectedUsers.length;
        const baseAmount = (totalAmount / userCount).toFixed(2);

        // Calculate remainder to adjust for rounding issues
        let totalAssigned = (baseAmount * userCount).toFixed(2);
        let remainder = (totalAmount - totalAssigned).toFixed(2);

        // Create breakdown array and adjust one user for rounding difference if necessary
        updatedExpense.expense_user_breakdown = selectedUsers.map((user, index) => {
            let amount = parseFloat(baseAmount);

            // Distribute remainder by adding it to the first user's amount
            if (index === 0) {
                amount += parseFloat(remainder);
            }

            return {
                user_id: user.user_id,
                amount: parseFloat(amount.toFixed(2)), // Ensure two decimal places
            };
        });
    }

    // Call onUpdate to save the updated expense
    onUpdate(currentIndex, updatedExpense);
};


  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
        onDelete(currentIndex);
    }
};

  const handleAmountChange = (user_id, value) => {
    // Clone the current breakdown to avoid direct state mutation
    const currentBreakdown = [...currentExpense.expense_user_breakdown];

    const newExpenseAssigned = { user_id: user_id, amount: parseFloat(value) };
    
    // Find the index of the existing user entry
    const index = currentBreakdown.findIndex(item => item.user_id === user_id);
    
    if (index !== -1) {
        // Update the amount for the existing entry
        currentBreakdown[index] = newExpenseAssigned;
    } else {
        // Add a new entry if the user is not found in the breakdown
        currentBreakdown.push(newExpenseAssigned);
    }
    
    // Create a new expense object with the updated breakdown
    const updatedExpense = { ...currentExpense, expense_user_breakdown: currentBreakdown };
    
    // Use onUpdate to save the updated expense in the parent component
    onUpdate(currentIndex, updatedExpense);
};

const handleCategoryChange = (event) => {
    const updatedExpense = { ...expenses[currentIndex], category: event.target.value };
    onUpdate(currentIndex, updatedExpense);
  };


  if (expenses.length === 0) return null;

  const selectedGroup = currentExpense.group_name;
  
  const group_options = groups.map(item => ({
    group_id: item.group_id,
    group_name: item.group_name
    }));

  const distinct_groups = Array.from(new Map(group_options.map(item => [JSON.stringify(item), item])).values());

  const selected_group_id = distinct_groups.filter(item => item.group_name===selectedGroup)[0].group_id;

  const user_options = groups.map(item => ({
    group_id: item.group_id,
    user_name: item.user_name,
    user_id: item.id
    }));

  const selected_users = user_options.filter(item => item.group_id===selected_group_id);
  
    // Calculate the remaining amount differently based on the group
    const totalAssignedAmount = currentExpense.expense_user_breakdown
        .reduce((sum, breakdown) => sum + parseFloat(breakdown.amount || 0), 0);

    // Set remainingAmount to zero if the group is "Personal"
    const remainingAmount = selectedGroup === 'Personal'
        ? 0
        : parseFloat(currentExpense.amount || 0) - totalAssignedAmount;

  return (
    <div className="carousel-container">
      <div className="carousel-item">
        <h2>Edit Expense</h2>
        <p>
          Expense {currentIndex + 1} of {expenses.length}
        </p>
        <label>
          Date:
          <input
            type="date"
            name="date"
            value={currentExpense.date}
            onChange={handleChange}
          />
        </label>
        <label>
          Description:
          <input
            type="text"
            name="description"
            value={currentExpense.description}
            onChange={handleChange}
          />
        </label>
        <label>
          Amount:
          <input
            type="number"
            name="amount"
            value={currentExpense.amount}
            onChange={handleChange}
          />
        </label>
        <label>
          Group:
          <select value={selectedGroup} onChange={handleGroupChange}>
            {distinct_groups.map((group) => (
              <option key={group.group_id} value={group.group_name}>
                {group.group_name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Category:
          <select value={currentExpense.category || ''} onChange={handleCategoryChange}>
            <option value="" disabled>
              Select Category
            </option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </label>
        {selectedGroup !== 'Personal' && (
    <>
        {selected_users.map((user) => {
            const userBreakdown = currentExpense.expense_user_breakdown.find(item => item.user_id === user.user_id);
            const userAmount = userBreakdown ? parseFloat(userBreakdown.amount) : 0;
            const totalAmount = parseFloat(currentExpense.amount) || 1; // Default to 1 to prevent division by zero
            const percentage = ((userAmount / totalAmount) * 100).toFixed(2);

            return (
                <div key={user.user_id}>
                    <label>
                        {user.user_name}:
                        <input
                            type="number"
                            value={userAmount}
                            onChange={(e) => handleAmountChange(user.user_id, e.target.value)}
                        />
                        <span> ({percentage}%)</span>
                    </label>
                </div>
            );
        })}

        {/* Summary section */}
        <div className="expense-summary">
            <p>Total Assigned: ${totalAssignedAmount.toFixed(2)}</p>
            <p>Remaining Amount: ${remainingAmount.toFixed(2)}</p>
        </div>
        </>
        )}

        <div className="carousel-buttons">
            <button onClick={handlePrev}>Previous</button>
            <button onClick={handleDelete}>Delete</button>
            <button onClick={handleNext} >Next</button>
        </div>
      </div>
    </div>
  );

};

export default ExpenseCarousel;
